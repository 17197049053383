import { useMutation } from '@tanstack/react-query';
import { apiClient } from '../../../../http/query-client';
import { useTrans } from '../../../../i18n/use-trans';
import { BackendResponse } from '../../../../http/backend-response/backend-response';
import { toast } from '../../../../ui/toast/toast';
import { message } from '../../../../i18n/message';
import { onFormQueryError } from '../../../../errors/on-form-query-error';
import { UseFormReturn } from 'react-hook-form';
import { SupportPayments } from '@common/billing/support-payments';
import { usePrimaryArtistForCurrentUser } from '@app/web-player/backstage/use-primary-artist-for-current-user';
import { useNavigate } from 'react-router-dom';

interface Response extends BackendResponse {
    
}

export interface RedeemPointsPayload {
  user_id: string;
  redeem_name: string;
  redeem_value: number;
  redeem_instructions: string;
}

export function useRedeemPoints(
  form: UseFormReturn<RedeemPointsPayload>
) {
  const { trans } = useTrans();
  const navigate = useNavigate();

  return useMutation(
    (props: RedeemPointsPayload) =>
      withdrawSupportWithdraws(props),
    {
      onSuccess: () => {
        toast(trans(message('Successfully redeemed points.')));
        // setTimeout(function(){
            navigate('#');
        // }, 400);
      },
      onError: err => {
        onFormQueryError(err, form);
        const errorMessage =
          trans(message('All fields are required.'));
        toast(errorMessage, { type: 'danger' });
      },
    }
  );
}

function withdrawSupportWithdraws({
  user_id,
  redeem_name,
  redeem_value,
  redeem_instructions,
}: RedeemPointsPayload): Promise<Response> {
  return apiClient
    .post(`billing/points-redeem-new`, { user_id, redeem_name, redeem_value, redeem_instructions })
    .then(r => r.data);
}
